<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <settings />
      </template>
    </flixDashboard>
  </div>
</template>
<script>
export default {
  components: {
    settings () { return import('@/components/dashboard/navbarFooter_old') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
